import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { updateWorkValidations } from '@atom/actions/workValidationsActions';
import { WorkValidations } from '@atom/types/work';
import { removeFromSet } from '@atom/utilities/setUtilities';
import { cleanWorkValidations } from '@atom/utilities/workValidationUtilities';

export const INITIAL_WORK_VALIDATIONS: WorkValidations = {
  workOrderId: null,
  taskValidations: null,
};

interface Data {
  // workValidations is a complex error state for various validations on a workOrder
  // It denotes fine grain errors that are found by the BE when users try to complete
  // tasks or work
  workValidations: WorkValidations;
  // setter for workValidations state
  setWorkValidations: (input: WorkValidations) => void;
  // Removes given task field from the validation object
  resolveTaskFieldError: (taskId: string, fieldId: string) => void;
  // Removes the missingRequiredAsset error from the given task
  resolveMultiAssetError: (taskId: string) => void;
  // Removes the missingRequiredLocation error from the given task
  resolveMultiLocationError: (taskId: string) => void;
}

export const useWorkValidations = (): Data => {
  const dispatch = useDispatch();

  const workValidations = useSelector(state => state.workValidations);
  const setWorkValidations = (newWorkValidations: WorkValidations) =>
    dispatch(updateWorkValidations(cleanWorkValidations(newWorkValidations)));

  const resolveTaskFieldError = (taskId: string, fieldId: string) => {
    const path = R.lensPath(['taskValidations', taskId, 'invalidTaskFields']);

    const updatedInvalidTaskFields = removeFromSet(
      R.view(path, workValidations) || new Set([]),
      fieldId,
    );

    const updatedValidations = R.set(
      path,
      updatedInvalidTaskFields,
    )(workValidations);

    setWorkValidations(updatedValidations);
  };

  const resolveMultiAssetError = (taskId: string) => {
    const path = R.lensPath([
      'taskValidations',
      taskId,
      'missingRequiredAsset',
    ]);

    const updatedValidations = R.set(path, false)(workValidations);

    setWorkValidations(updatedValidations);
  };

  const resolveMultiLocationError = (taskId: string) => {
    const path = R.lensPath([
      'taskValidations',
      taskId,
      'missingRequiredLocation',
    ]);

    const updatedValidations = R.set(path, false)(workValidations);

    setWorkValidations(updatedValidations);
  };

  return {
    workValidations,
    setWorkValidations,
    resolveTaskFieldError,
    resolveMultiAssetError,
    resolveMultiLocationError,
  };
};
